import React from 'react';

function About() {
  return (
    <div className="about neon-border">
      <h1 className="lygofast-title">About Lygofast</h1>
      <div className="about-description">
        <p>Lygofast is a visionary music producer and DJ in the Electronic Dance Music (EDM) scene, known for his unique talent in using vocal chops to recreate entire songs. His live performances stand out as every track played is something he has originally produced or personally remixed from a popular song. Lygofast takes pride in ensuring that all music at his events carries his creative touch—never playing a track that he didn't have a hand in creating.</p>
        <p>At each live event, Lygofast adds an extra layer of excitement by performing in costume, creating an immersive experience for the audience. Alongside him at every performance is his partner, The Wife Jessica, who brings her own energy and presence to every event and music session.</p>
      </div>
      <style jsx>{`
        .about {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          min-height: calc(100vh - 80px); /* Adjust based on your nav height */
          padding: 20px;
        }
        .about-description {
          max-width: 800px;
          margin: 20px 0;
          font-size: 1.1rem;
          line-height: 1.6;
          text-align: justify;
        }
        .about-description p {
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
}

export default About;