import React, { useState, useEffect, useCallback } from 'react';
// Remove individual imports and use a single array for image paths
const imagePaths = [
  'gallery/Lygo1.jpg',
  'gallery/Lygo2.jpg',
  'gallery/Lygo3.jpg',
  'gallery/Lygo4.jpg',
  'gallery/Lygo5.jpg',
  'gallery/Lygo6.jpg',
  'gallery/Lygo7.jpg',
  'gallery/Lygo8.jpg',
  'gallery/Lygo9.jpg',
  'gallery/Lygo10.jpg',
  'gallery/Lygo11.jpg',
  'gallery/Lygo12.jpg',
  'gallery/Lygo13.jpg',
  'gallery/Lygo14.jpg',
];

function Gallery() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);

  const preloadImage = useCallback((src) => {
    if (!preloadedImages.includes(src)) {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}/${src}`;
      setPreloadedImages(prev => [...prev, src]);
    }
  }, [preloadedImages]);

  useEffect(() => {
    // Preload the first image
    preloadImage(imagePaths[0]);
  }, [preloadImage]);

  useEffect(() => {
    // Preload next and previous images
    const nextIndex = (currentImageIndex + 1) % imagePaths.length;
    const prevIndex = currentImageIndex === 0 ? imagePaths.length - 1 : currentImageIndex - 1;

    preloadImage(imagePaths[nextIndex]);
    preloadImage(imagePaths[prevIndex]);
  }, [currentImageIndex, preloadImage]);

  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? imagePaths.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentImageIndex((prevIndex) => 
      (prevIndex + 1) % imagePaths.length
    );
  };

  return (
    <div className="gallery neon-border">
      <h1 className="lygofast-title">Lygofast's Gallery</h1>
      <div className="gallery-image-wrapper">
        <div className="gallery-image-container">
          <img 
            src={`${process.env.PUBLIC_URL}/${imagePaths[currentImageIndex]}`} 
            alt={`Lygofast performance ${currentImageIndex + 1}`} 
            className="gallery-image"
          />
          <div className="image-counter">
            {currentImageIndex + 1} / {imagePaths.length}
          </div>
        </div>
        <div className="gallery-controls">
          <button onClick={goToPrevious} className="hover-glow-btn"><span>Previous</span></button>
          <button onClick={goToNext} className="hover-glow-btn"><span>Next</span></button>
        </div>
      </div>
      <style jsx>{`
        .gallery {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 20px;
        }
        .gallery-image-wrapper {
          width: 100%;
          max-width: 500px;
          margin: 20px auto;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .gallery-image-container {
          position: relative;
          display: inline-block;
          margin-bottom: 20px;
        }
        .gallery-image {
          max-width: 100%;
          max-height: 400px;
          object-fit: contain;
          position: relative;
          z-index: 1;
        }
        .gallery-image-container::before,
        .gallery-image-container::after {
          content: '';
          position: absolute;
          top: -10px;
          left: -10px;
          right: -10px;
          bottom: -10px;
          background: #b300ff;
          z-index: -1;
          border-radius: 10px;
        }
        .gallery-image-container::before {
          filter: blur(15px);
          opacity: 0.7;
        }
        .gallery-image-container::after {
          filter: blur(30px);
          opacity: 0.5;
        }
        .gallery-controls {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-top: 20px;
        }
        .image-counter {
          position: absolute;
          bottom: -30px;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 1rem;
          color: var(--text-color);
        }
      `}</style>
    </div>
  );
}

export default Gallery;