import React, { useState } from 'react';
import MediaPlayer from './MediaPlayer';
import { houseRemixes } from '../houseRemixes';
import { rapRemixes } from '../rapRemixes';

function Home() {
  const [currentPlaylist, setCurrentPlaylist] = useState(null);
  const [playlistType, setPlaylistType] = useState('');

  const handleHouseRemixes = () => {
    setCurrentPlaylist(houseRemixes);
    setPlaylistType('House Remixes');
  };

  const handleRapRemixes = () => {
    setCurrentPlaylist(rapRemixes);
    setPlaylistType('Rap Remixes');
  };

  return (
    <div className="home">
      <h1 className="lygofast-title">Lygofast</h1>
      <div className="button-container">
        <button onClick={handleHouseRemixes} className="hover-glow-btn"><span>House Remixes</span></button>
        <button onClick={handleRapRemixes} className="hover-glow-btn"><span>Rap Remixes</span></button>
      </div>
      <div className="media-player-container">
        {currentPlaylist && <MediaPlayer playlist={currentPlaylist} playlistType={playlistType} />}
      </div>
      <style jsx>{`
        .home {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          min-height: 100vh;
        }
        .button-container {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-bottom: 40px;
          width: 100%;
        }
        .media-player-container {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      `}</style>
    </div>
  );
}

export default Home;