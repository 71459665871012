import React, { useState, useEffect, useRef } from 'react';

function MediaPlayer({ playlist, playlistType }) {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    // Reset state when playlist changes
    setCurrentTrackIndex(0);
    setProgress(0);
    setCurrentTime(0);
    setDuration(0);
    setIsPlaying(true); // Start playing automatically when playlist changes
  }, [playlist]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play().catch(error => console.error("Playback failed:", error));
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, currentTrackIndex]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleStop = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setProgress(0);
    setCurrentTime(0);
  };

  const handlePrevTrack = () => {
    setCurrentTrackIndex((prevIndex) => 
      prevIndex === 0 ? playlist.length - 1 : prevIndex - 1
    );
  };

  const handleNextTrack = () => {
    setCurrentTrackIndex((prevIndex) => 
      prevIndex === playlist.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const current = audioRef.current.currentTime;
      const duration = audioRef.current.duration;
      setCurrentTime(current);
      setDuration(duration);
      setProgress((current / duration) * 100);
    }
  };

  const handleProgressChange = (e) => {
    if (audioRef.current) {
      const newTime = (e.target.value / 100) * audioRef.current.duration;
      audioRef.current.currentTime = newTime;
      setProgress(e.target.value);
      setCurrentTime(newTime);
    }
  };

  const handleTrackSelect = (index) => {
    setCurrentTrackIndex(index);
    setIsPlaying(true);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (!playlist || playlist.length === 0) {
    return <div className="media-player">No tracks available</div>;
  }

  const currentTrack = playlist[currentTrackIndex];

  if (!currentTrack) {
    return <div className="media-player">Invalid track selected</div>;
  }

  return (
    <div className="media-player">
      <audio
        key={`${currentTrackIndex}-${currentTrack.url}`}
        ref={audioRef}
        src={currentTrack.url}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleNextTrack}
        onCanPlay={() => {
          if (isPlaying) audioRef.current.play();
        }}
      />
      <div className="track-info">
        <p className="title">{currentTrack.title}</p>
        <p className="artist">{currentTrack.artist}</p>
      </div>
      <div className="controls">
        <button onClick={handlePrevTrack} className="control-btn">⏮</button>
        <button onClick={handlePlayPause} className="control-btn play-pause">
          {isPlaying ? '⏸' : '▶'}
        </button>
        <button onClick={handleStop} className="control-btn">⏹</button>
        <button onClick={handleNextTrack} className="control-btn">⏭</button>
      </div>
      <div className="progress-container">
        <span className="time">{formatTime(currentTime)}</span>
        <input
          type="range"
          value={progress}
          onChange={handleProgressChange}
          min="0"
          max="100"
          className="progress-bar"
        />
        <span className="time">{formatTime(duration)}</span>
      </div>
      <div className="playlist">
        <h3>Lygofast {playlistType}</h3>
        <ul>
          {playlist.map((track, index) => (
            <li 
              key={index} 
              onClick={() => handleTrackSelect(index)}
              className={index === currentTrackIndex ? 'active' : ''}
            >
              {track.artist} - {track.title}
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .media-player {
          width: 100%;
          max-width: 400px;
          padding: 20px;
          background-color: rgba(157, 0, 255, 0.2); /* Transparent neon purple */
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border-radius: 15px;
          color: #fff;
          border: 1px solid rgba(255, 255, 255, 0.18);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        }
        .track-info {
          text-align: center;
          margin-bottom: 20px;
        }
        .title {
          font-size: 1.2em;
          font-weight: bold;
          margin-bottom: 5px;
          text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
        }
        .artist {
          font-size: 1em;
          color: rgba(255, 255, 255, 0.8);
        }
        .controls {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
        .control-btn {
          background: none;
          border: none;
          color: #fff;
          font-size: 24px;
          cursor: pointer;
          margin: 0 15px;
          transition: all 0.3s ease;
        }
        .control-btn:hover {
          text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
        }
        .progress-container {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
        .time {
          font-size: 0.8em;
          width: 45px;
        }
        .progress-bar {
          flex-grow: 1;
          margin: 0 10px;
          -webkit-appearance: none;
          background: rgba(255, 255, 255, 0.3);
          outline: none;
          opacity: 0.7;
          transition: opacity .2s;
          border-radius: 5px;
          height: 5px;
        }
        .progress-bar:hover {
          opacity: 1;
        }
        .progress-bar::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 15px;
          height: 15px;
          background: #fff;
          cursor: pointer;
          border-radius: 50%;
        }
        .progress-bar::-moz-range-thumb {
          width: 15px;
          height: 15px;
          background: #fff;
          cursor: pointer;
          border-radius: 50%;
        }
        .playlist {
          margin-top: 20px;
        }
        .playlist h3 {
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 10px;
        }
        .playlist ul {
          list-style-type: none;
          padding: 0;
          max-height: 200px;
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: rgba(255, 255, 255, 0.5) rgba(157, 0, 255, 0.1);
        }
        .playlist ul::-webkit-scrollbar {
          width: 8px;
        }
        .playlist ul::-webkit-scrollbar-track {
          background: rgba(157, 0, 255, 0.1);
          border-radius: 4px;
        }
        .playlist ul::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 4px;
          border: 2px solid rgba(157, 0, 255, 0.1);
        }
        .playlist ul::-webkit-scrollbar-thumb:hover {
          background-color: rgba(255, 255, 255, 0.7);
        }
        .playlist li {
          cursor: pointer;
          padding: 8px 0;  /* Increased padding */
          margin-bottom: 4px;  /* Added margin between items */
          transition: all 0.3s ease;
        }
        .playlist li:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        .playlist li.active {
          font-weight: bold;
          color: #fff;
          background-color: rgba(255, 255, 255, 0.2);
        }
      `}</style>
    </div>
  );
}

export default MediaPlayer;