export const rapRemixes = [
  {
    title: "Empty",
    artist: "Juice Wrld",
    url: "/songs/rap-remixes/Juice_Wrld-Empty_Rap_Remix.mp3"
  },
  {
    title: "BBHMM",
    artist: "Rihanna",
    url: "/songs/rap-remixes/Rihanna-BBHMM_Rap_Remix.mp3"
  },
  // Add more rap remixes here
];