export const houseRemixes = [
    {
      title: "Dance The Night Away",
      artist: "Dua Lipa",
      url: "/songs/house-remixes/Dua_Lipa-Dance_The_Night_Away_House_Remix.mp3"
    },
    {
      title: "I Love it",
      artist: "Icona Pop Feat Charli XCX",
      url: "/songs/house-remixes/Icona_Pop_Feat_Charli_XCX-I_love_it_House_Remix.mp3"
    },
    {
      title: "The Edge of Glory",
      artist: "Lady Gaga",
      url: "/songs/house-remixes/Lady_Gaga-The_Edge_of_Glory_House_Remix.mp3"
    },
    {
      title: "Rain on Me",
      artist: "Lady Gaga ft Ariana Grande",
      url: "/songs/house-remixes/Lady_Gaga_ft_Ariana_Grande-Rain_on_Me_House_Remix.mp3"
    },
    {
      title: "Talking Body",
      artist: "Tove Lo",
      url: "/songs/house-remixes/Tove_Lo-Talking_Body_House_Remix.mp3"
    },
    {
      title: "Kiss it Better",
      artist: "Rihanna",
      url: "/songs/house-remixes/Rihanna-Kiss_it_Better_House_Remix.mp3"
    },
    {
      title: "Wasted",
      artist: "Tiësto ft. Matthew Koma",
      url: "/songs/house-remixes/Tiesto-Wasted_ft_Matthew_Koma_House_Remix.mp3"
    },
    {
      title: "That's What You Get",
      artist: "Paramore",
      url: "/songs/house-remixes/Paramore-Thats_What_You_Get_House_Remix.mp3"
    }
  ];